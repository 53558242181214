import { computed, observable, subscribe } from 'knockout-decorators'

import { CartPrecheckStatusEnglish, CheckResult } from '@tixa/schema'

export class ReservationState {
  private seatmapId = ''

  constructor(params: { seatmapId: string }) {
    this.seatmapId = params.seatmapId
  }

  @observable
  seatmap: any = null

  @observable
  seatmapReservations: any = null

  @observable
  isSeatmapOpen = false

  @computed
  get isAvailable(): boolean {
    return (
      this.seatmap?.itemContainer.activeSeatCount > 0 ||
      this.seatmap?.tickets.isAnyStandingTicketAvailable()
    )
  }

  @computed get submit(): {
    id: string
    action: () => Promise<CheckResult>
    isEnabled: boolean
  } {
    return {
      action: () => this.submitAction(),
      id: this.seatmapId,
      isEnabled: this.isSeatmapOpen,
    }
  }

  private submitAction(): Promise<CheckResult> {
    return new Promise<CheckResult>((resolve) => {
      // Subscribe to new reservations
      // Reservations are added on seatmap config callback
      subscribe<any>(
        () => this.seatmapReservations,
        // When there is a value, resolve the promise
        (reservation) => {
          resolve({
            status: CartPrecheckStatusEnglish.success,
            isCheckSuccess: true,
            reservation,
          })
          //this.isSeatmapClosed = true;
        }
      )
      // Trigger seatmap send
      this.seatmap.sendTickets()
    })
  }
}
