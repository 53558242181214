import { computed, observable } from 'knockout-decorators'

import {
  CartPrecheckStatusEnglish,
  CheckResult,
  CountedTicketRequest,
  OfferAvailability,
} from '@tixa/schema'

import type { Offer } from '@tixa/schema'

import { ErrorReporter, Language, Locale, Tracker } from '~/utils'
import { pageState } from '~/store/page'

export class TicketState {
  constructor(offer: Offer) {
    this.offer = offer
  }

  private more() {
    this.offer.eligibleQuantity.value = this.cartCount + 1
    if (Tracker.addedToCart) {
      Tracker.addedToCart({
        name: this.offer.name,
        offerId: this.offer['@id'],
        ticketTypeName: this.offer.description,
        ticketTypeId: this.offer.sku,
        offerPrice: this.offer.price,
        currency: this.offer.priceCurrency,
        quantity: this.offer.eligibleQuantity.value,
        coupon: this.offer.appliedCoupon || undefined,
        type: this.offer.category || undefined,
      })
    }
  }

  private less() {
    this.offer.eligibleQuantity.value = Math.max(this.cartCount - 1, 0)
  }

  @computed
  get canGetMore(): boolean {
    return this.maxCount > -1 && this.cartCount < this.maxCount
  }

  @computed
  get canGetLess(): boolean {
    return this.cartCount > 0
  }

  @computed
  get inactiveStatus(): string {
    return this.offer && this.offer.customAvailability
      ? this.offer.customAvailability
      : this.isDiscontinued
      ? Locale.translations.inactiveprice
      : !this.isAvailable
      ? Locale.translations.soldout
      : ''
  }

  @computed
  get isAvailable(): boolean {
    const availableCategories: OfferAvailability[] = [
      OfferAvailability.InStock,
      OfferAvailability.Discontinued,
    ]
    return availableCategories.includes(this.offer.availability)
  }

  @computed
  get isDiscontinued(): boolean {
    return this.offer.availability === OfferAvailability.Discontinued
  }

  @computed
  get isFree(): boolean {
    return this.offer.price === 0
  }

  @computed
  get price(): string {
    if (this.isFree) {
      return Locale.translations.free
    }
    return Language.formatCurrency(this.offer.price, this.offer.priceCurrency)
  }

  @computed
  get maxCount(): number {
    return this.offer.eligibleQuantity?.maxValue || -1
  }

  @computed
  get hasCouponApplied(): boolean {
    return this.offer.category === 'coupon'
  }

  @computed
  get hasCodeApplied(): boolean {
    return this.offer.category === 'code'
  }

  @observable offer: Offer

  @computed
  get cartCount(): number {
    return this.offer.eligibleQuantity?.value || 0
  }

  @computed
  get isValid(): boolean {
    if (!this.offer || !this.offer.eligibleQuantity) {
      return false
    }
    const isCartCountInRange =
      this.maxCount === -1 || this.cartCount <= this.maxCount
    return this.isAvailable && this.cartCount > 0 && isCartCountInRange
  }

  @computed get isValidToSubmit(): boolean {
    const isCartCountInRange =
      this.maxCount === -1 || this.cartCount <= this.maxCount
    return this.isAvailable && this.cartCount > 0 && isCartCountInRange
  }

  get cartData(): CountedTicketRequest {
    return {
      darab: this.cartCount,
      jegytipusid: this.offer.sku,
      arfejlodesid: this.offer['@id'],
      maxdarabszamok: this.maxCount,
      jegytipusnev: this.offer.name,
      code: this.hasCodeApplied ? this.offer.appliedCode : undefined,
      coupon: this.hasCouponApplied ? this.offer.appliedCoupon : undefined,
      couponType: this.offer.appliedCouponType,
      rendezvenyid: this.offer.eventID,
    }
  }

  private async submitAction(): Promise<CheckResult> {
    if (!this.isValidToSubmit) {
      return {} as CheckResult
    }

    let status: CartPrecheckStatusEnglish
    try {
      const result = await pageState.api.cart.preCheck(
        this.offer.sku,
        this.offer['@id'],
        this.cartCount,
        this.hasCouponApplied ? this.offer.appliedCoupon : undefined,
        this.hasCodeApplied ? this.offer.appliedCode : undefined
      )

      status = result.status
    } catch (error) {
      ErrorReporter.exception(error as Error)
      status = CartPrecheckStatusEnglish.error
    }

    return {
      status,
      isCheckSuccess: status === CartPrecheckStatusEnglish.success,
      countedTicket: this.cartData,
    }
  }

  @computed get submit(): {
    id: string
    action: () => Promise<CheckResult>
    isEnabled: boolean
  } {
    return {
      action: () => this.submitAction(),
      id: this.offer['@id'],
      isEnabled: this.isValid,
    }
  }
}
