import { logger } from '~/utils/log'

declare const PRODUCTION: boolean

const CART_EXPIRY_CHECK_INTERVAL = 500
const CART_EXPIRY_DEFAULT_EXPIRATION = PRODUCTION ? 300000 : 1000000

const useCartExpiryCheckTimer = (
  isPaused: () => boolean,
  onExpired: () => void,
  onTick: (remaining: number) => void
) => {
  let timer: ReturnType<typeof setTimeout> | null = null
  let expirationLimit = -1
  let elapsedTime = 0
  let lastCheck = -1

  const start = async (
    limit: number = CART_EXPIRY_DEFAULT_EXPIRATION
  ): Promise<number> => {
    // When there is an existing timer with a different limit
    // we remove the timer and create a new one
    if (timer && expirationLimit !== limit) {
      await stop()
    }

    if (!timer) {
      logger.debug(
        'CART',
        'Starting cart expiry checking:',
        (limit / 1000).toFixed(2),
        'seconds'
      )
      lastCheck = Date.now()
      expirationLimit = limit
      elapsedTime = 0
      timer = setInterval(async () => await check(), CART_EXPIRY_CHECK_INTERVAL)
    }
    return expirationLimit
  }
  const stop = async () => {
    if (timer) {
      logger.debug('CART', 'Stopping cart expiry checking')
      clearInterval(timer)
      timer = null
    }
  }

  const check = async () => {
    if (timer) {
      const currentTime = Date.now()

      if (!isPaused()) {
        const timeSinceLastCheck = currentTime - lastCheck
        elapsedTime += timeSinceLastCheck
        logger.debug(
          'CART',
          'Remaining cart expiry time:',
          ((expirationLimit - elapsedTime) / 1000).toFixed(2),
          'seconds'
        )
      } else {
        logger.debug('CART', 'Expiry checking is paused')
      }
      lastCheck = currentTime

      onTick(expirationLimit - elapsedTime)

      // If cart has expired, we clear it
      if (expirationLimit - elapsedTime < 0) {
        logger.debug('CART', 'Cart has expired')
        onExpired()
      }
    }
  }

  return {
    start,
    stop,
  }
}

export { useCartExpiryCheckTimer }
