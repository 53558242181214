import { Storage, Translations } from '~/utils'
import { EventState } from '../event'
import { Interactor } from './interactor'

import {
  CartPrecheckStatusEnglish,
  CartRequest,
  CountedTicketRequest,
  ReservationRequest,
} from '@tixa/schema'

import { CartState } from '~/components/cart/state'

export class CartSubmitInteractor extends Interactor<EventState> {
  private cartState = CartState.get()

  private readonly errorMessages = new Map<CartPrecheckStatusEnglish, string>([
    [CartPrecheckStatusEnglish.codeExpired, Translations.codeused],
    [CartPrecheckStatusEnglish.couponExpired, Translations.codeused],
    [CartPrecheckStatusEnglish.failure, Translations.waiterror],
    [CartPrecheckStatusEnglish.error, Translations.waiterror],
    [CartPrecheckStatusEnglish.invalid, Translations.waiterror],
  ])

  constructor(state: EventState) {
    super(state)
  }

  public async buy(): Promise<void> {
    if (
      Storage.hasInStore('cartID', true) &&
      Storage.hasInStore('cartTime', true) &&
      Number(Storage.getFromStore('cartTime', true)) + 5000 > Date.now()
    ) {
      this.state.globalError = Translations.onecart
      return
    }

    const countedTickets: CountedTicketRequest[] = []
    const reservations: ReservationRequest[] = []
    let allTicketsGo = true
    let errorMessage = ''

    const actions = [
      ...Object.values(this.state.offerSates).map((state) => state.submit),
      ...Object.values(this.state.reservationStates).map(
        (state) => state.submit
      ),
    ]

    this.cartState.isPrechecking = true

    const checkActions = actions
      .filter((action) => action.isEnabled)
      .map((action) => action.action())

    for await (const checkStatus of checkActions) {
      if (checkStatus) {
        if (!checkStatus.isCheckSuccess) {
          errorMessage =
            this.errorMessages.get(checkStatus.status) || 'Unknown error'
          allTicketsGo = false
        } else {
          if (checkStatus.countedTicket) {
            countedTickets.push(checkStatus.countedTicket)
          }
          if (checkStatus.reservation) {
            reservations.push(...checkStatus.reservation)
          }
        }
      }
    }

    if (!allTicketsGo) {
      this.cartState.isPrechecking = false
      this.state.globalError = errorMessage
      return
    }

    this.createCartRequest({
      reservations,
      countedTickets,
    })
    this.cartState.isPrechecking = false
  }

  public createCartRequest(data: CartRequest): void {
    this.cartState.createRequest(data)
    //this.state.cartRequest = pageState.api.cart.requestCart(data)
    this.state.specialOffers.couponChips.removeAll()
    this.state.specialOffers.invalidCoupons.removeAll()
    this.state.specialOffers.validCoupons.removeAll()
  }
}
