import {
  component,
  computed,
  observable,
  observableArray,
  subscribe,
} from 'knockout-decorators'

import type { EventItem, ItemList } from '@tixa/schema'

import { ErrorReporter } from '../../utils/error-reporting'
import { getPageData } from '../../utils/ajax'
import { Graphics } from '../../utils/graphics'
import SrcSets from '../../utils/srcSets'

import { pageState } from '~/store/page'
import { Background, ImageProcessStep } from '../../pages/background'

import '../image-changer/image-changer'
import './slider.less'
import source from './slider.html?raw'

@component('slider', source)
export class Slider {
  @computed get optimalControlShadow(): string {
    return '0px 2px 3px ' + 'rgba(0,0,0,0.2)'
  }

  @computed get shouldAutoAdvanceRun(): boolean {
    return (
      !this.isLoading &&
      !this.hasAdvancedManually &&
      this.isSliderInView &&
      this.isWindowVisible &&
      !pageState.blockAnimations
    )
  }

  // AutoAdvancer
  @observable public isWindowVisible = true
  @observable public isSliderInView = true
  @observable public hasAdvancedManually = false
  @observable public isLoading = true
  @observable public isEmpty = false

  @observable public currentTitle = ' - '
  @observable public currentLink = ' '
  @observable public currentDate = ' - '

  @observableArray public events: EventItem[] = []
  @observable public currentEvent: EventItem | null = null
  @observable public currentImage = ''

  private readonly backgroundImageProcessSteps = [
    ImageProcessStep.original,
    ImageProcessStep.colorOverlay,
    ImageProcessStep.blur,
    ImageProcessStep.shadow,
  ]
  private readonly autoAdvanceInterval = 4000
  private autoAdvanceIntervalID: NodeJS.Timeout | null = null
  private images = new Map<string, string>()

  private impressions = new Map<string, number>()

  constructor() {
    this.initAutoAdvancer()
    const sliderConfigData = getPageData<ItemList<EventItem>>('sliderConfig')
    if (sliderConfigData) {
      this.init(sliderConfigData)
    } else {
      this.isLoading = false
      this.isEmpty = true
    }
  }

  private registerChanger() {
    window.onscroll = () => {
      this.isSliderInView = window.scrollY < 630
    }
    window.onblur = () => {
      this.isWindowVisible = false
    }
    window.onfocus = () => {
      this.isWindowVisible = true
    }
  }

  private async loadEvent(event: EventItem) {
    if (event.image.length === 0) {
      return
    }
    const background = Background.getInstance()

    const image: HTMLImageElement = document.createElement('img')
    image.src = Graphics.hasSrcSet
      ? Graphics.getSrcWithSize(event.image[0], SrcSets.coverLq)
      : event.image[0]

    await background.addCanvasBackground(event.image[0], image)

    this.events.push(event)
  }

  private async init(data: ItemList<EventItem>) {
    const background = Background.getInstance()
    // this.events.push(...data.itemListElement.map((event) => event.item));

    const eventsToLoad = data.itemListElement.map((event) => event.item)

    eventsToLoad.forEach((event) => {
      this.images.set(
        event.image[0],
        Graphics.getSrcWithSize(
          event.image[0],
          Graphics.getClosestWidth(
            SrcSets.cover,
            Math.min(1000, window.innerWidth)
          )
        )
      )
    })

    const loadActions = eventsToLoad.map((eventItem) =>
      this.loadEvent(eventItem)
    )
    await Promise.all(loadActions)

    if (eventsToLoad.length > 0) {
      background.setCanvasBackground(eventsToLoad[0].image[0])
      this.setEvent(eventsToLoad[0])
    } else {
      this.isEmpty = true
    }
    this.isLoading = false
  }

  private initAutoAdvancer() {
    subscribe(
      () => this.shouldAutoAdvanceRun,
      (should) => {
        if (should) {
          setTimeout(() => {
            this.autoAdvanceIntervalID = setInterval(
              () => this.autoAdvance(this),
              this.autoAdvanceInterval
            )
          }, 1000)
        } else {
          this.clearAutoAdvancer()
        }
      }
    )
  }

  private clearAutoAdvancer() {
    if (this.autoAdvanceIntervalID !== null) {
      clearInterval(this.autoAdvanceIntervalID)
      this.autoAdvanceIntervalID = null
    }
  }
  private isActive(item: EventItem): boolean {
    if (null !== this.currentEvent) {
      return this.currentEvent === item
    }
    return false
  }

  private async setEvent(event: EventItem) {
    this.currentEvent = event
    this.currentTitle = event.name
    this.currentLink = event.url
    this.currentDate = event.customDate ? event.customDate : event.startDate

    this.currentImage = this.images.get(event.image[0]) || ''
    pageState.sliderImage = this.currentImage
    Background.getInstance().setCanvasBackground(event.image[0])

    this.increaseImpression(event, 'view')
  }

  private async increaseImpression(event: EventItem, action: string) {
    this.impressions.set(
      event.image[0],
      this.impressions.has(event.image[0])
        ? (this.impressions.get(event.image[0]) ?? 0) + 1
        : 1
    )
    try {
      if (!this.currentEvent) {
        return
      }
      await pageState.api.impression.submit({
        action,
        count: this.getImpressionCount(this.currentEvent),
        image: this.currentEvent.image[0],
        type: 'slider',
        url: this.currentEvent.url,
      })
    } catch (error) {
      ErrorReporter.exception(error as Error)
    }
  }

  private getImpressionCount(event: EventItem): number {
    if (!this.impressions.has(event.image[0])) {
      return 0
    }
    return this.impressions.get(event.image[0]) ?? 0
  }

  private select(item: EventItem) {
    this.hasAdvancedManually = true
    this.setEvent(item)
  }

  private async buyClicked() {
    if (this.currentEvent) {
      this.increaseImpression(this.currentEvent, 'click')
      window.location.href = this.currentEvent.url
    }
  }

  private autoAdvance(slider: Slider) {
    if (this.shouldAutoAdvanceRun && slider.currentEvent) {
      const currIndex = slider.events.indexOf(slider.currentEvent)
      const nextIndex =
        slider.events.length === currIndex + 1 ? 0 : currIndex + 1
      slider.setEvent(slider.events[nextIndex])
    } else {
      this.clearAutoAdvancer()
    }
  }
}
