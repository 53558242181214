import * as ko from 'knockout'
import { Translations } from '~/utils/language'

ko.bindingHandlers.load = {
  init: (element: HTMLElement, valueAccessor, allBindings, viewModel) => {
    element.addEventListener('load', () =>
      valueAccessor().apply(viewModel, [element])
    )
  },
}
ko.bindingHandlers.register = {
  init: (element: HTMLElement, valueAccessor, allBindings, viewModel) => {
    if (typeof valueAccessor() === 'string') {
      viewModel[valueAccessor()] = element
    } else {
      valueAccessor().apply(viewModel, [element])
    }
  },
}
ko.bindingHandlers.i18n = {
  init: (element: HTMLElement, valueAccessor) => {
    const value = valueAccessor()
    if (typeof value === 'object') {
      Object.entries(value).forEach((v) => {
        element.setAttribute(v[0], Translations[v[1] as string])
      })
    } else {
      element.innerText = Translations[value]
    }
  },
}
