import { Language } from '~/utils'
import { pageState } from '~/store/page'

export class AppNavigation {
  static home() {
    window.location.href = '/'
  }
  static tickets() {
    window.location.href = '/jegyeim'
  }
  static async switchLanguage() {
    const currentLanguage = Language.getInstance().language
    const newLanguage = currentLanguage === 'hu' ? 'en' : 'hu'
    try {
      await pageState.api.language.changeLanguage(newLanguage)
    } catch (error) {
      console.error('Error switching language', error)
    }
  }
}
