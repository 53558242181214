import { ErrorReporter } from './error-reporting'

declare const DEVELOPMENT: boolean

export type PageDataType =
  | 'pageConfig'
  | 'topLocationEvents'
  | 'sliderConfig'
  | 'mainFeed1'
  | 'mainFeed2'
  | 'locationData'
  | 'locationEvents'
  | 'organizerData'
  | 'organizerEvents'
  | 'eventConfig'
  | 'eventData'
  | `eventOffers-${string}`
  | 'tickets'

const allPageData = new Map<PageDataType, unknown>()

export const loadAllPageData = () => {
  const scripts = Array.from(window.document.getElementsByTagName('script'))
  for (const script of scripts) {
    const typeElement = script.attributes.getNamedItem('type')
    if (typeElement && typeElement.value === 'application/ld+json') {
      try {
        const doc = JSON.parse(script.innerText)
        if (doc['@id'] !== undefined) {
          allPageData.set(doc['@id'], doc)
        }
      } catch (err) {
        ErrorReporter.exception(err as Error)
      }
    }
  }
}

export function getPageData<T>(id: PageDataType): T | undefined {
  if (!allPageData.size) {
    loadAllPageData()
  }
  return allPageData.get(id) as T
}

export function loadScript(src: string, id?: string) {
  return new Promise((resolve, reject) => {
    const s = document.createElement('script')
    if (id) {
      s.id = id
    }
    s.async = true
    s.src = src
    s.onload = resolve
    s.onerror = reject
    document.head.appendChild(s)
  })
}

export function getPageCsrfToken(): string | undefined {
  let tokenElement = document.head.querySelector('[name=csrf-token]')
  if (tokenElement === null) {
    tokenElement = document.head.querySelector('[name=tixa-csrf-token]')
    if (tokenElement === null) {
      return undefined
    }
  }
  return tokenElement.getAttribute('content') ?? undefined
}

export function getPageBaseUrl(): string {
  const host =
    'tixaOrigin' in window ? window['tixaOrigin'] : window.location.origin
  const baseURL = `${host}${DEVELOPMENT ? '/dev-proxy' : ''}`
  return baseURL
}
