import type { Offer } from '@tixa/schema'

const isShippingOffer = (offer: Offer): boolean => offer.sku === '-1001'
const hasCouponApplied = (offer: Offer): boolean =>
  'appliedCoupon' in offer && (offer.appliedCoupon?.length ?? 0) > 0
const hasInCart = (offer: Offer): boolean => offer.eligibleQuantity.value > 0
const isFee = (offer: Offer): boolean => offer.category === 'fee'

const sha256 = async (text: string) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-256', data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

const getCookie = (name: string) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) {
    return parts.pop()?.split(';').shift()
  }
}

export {
  isShippingOffer,
  hasCouponApplied,
  hasInCart,
  isFee,
  sha256,
  getCookie,
}
