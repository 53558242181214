import { Offer, PageConfig } from '@tixa/schema'
import { AnalyticsEventsHandler } from './types'

import { isFee, isShippingOffer, hasInCart } from './utils'

const createGtagScript = (gtagId: string) => {
  const gtagScript = document.createElement('script')
  gtagScript.setAttribute('async', '')
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagId}`
  document.head.append(gtagScript)
}

const createGtmScript = (gtmId: string) => {
  const gtmScript = document.createElement('script')
  gtmScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${gtmId}');`
  document.head.append(gtmScript)
}

const createCartAction = (offers: Offer[]) => {
  const gtagObj: GaActionData = {
    items: convertOffers(offers.filter(hasInCart)),
    shipping: offers
      .filter(isShippingOffer)
      .reduce((prev, curr) => prev + curr.price, 0),
    tax: offers.filter(isFee).reduce((prev, curr) => prev + curr.price, 0),
    value: offers
      .filter(hasInCart)
      .reduce((prev, curr) => prev + curr.price, 0),
  }
  const coupon = offers
    .filter((offer) => offer.appliedCoupon !== undefined)
    .map((offer) => offer.appliedCoupon)
    .reduce((prev, curr) => {
      return `${prev}${prev ? ' / ' : ''}${curr}`
    }, '')

  if (coupon && coupon.length > 0) {
    gtagObj.coupon = coupon
  }
  return gtagObj
}

const convertOffers = (offers: Offer[]): GaProduct[] => {
  return offers.map((offer) => {
    const descriptionValue = Array.isArray(offer.description)
      ? offer.description[0]
      : offer.description
    const obj: GaProduct = {
      item_id: offer.sku,
      item_name: offer['@id'],
      item_category: descriptionValue,
      quantity: offer.eligibleQuantity.value,
      price: offer.price,
      currency: offer.priceCurrency,
      item_variant: offer.category,
    }
    if (offer.appliedCoupon && offer.appliedCoupon.length > 0) {
      obj.coupon = offer.appliedCoupon
    }
    return obj
  })
}

const createGoogleAnalyticsHandler = (): AnalyticsEventsHandler => {
  let isActive = false

  const propertyIds: string[] = []
  let conversionId: string

  const checkoutStartTime = new Date()
  const paymentStartTime = new Date()
  let queueStartTime = new Date()

  // Helper function to send data to Google Analytics
  const getFinishTime = (startTime: Date) => {
    return Math.round((new Date().getTime() - startTime.getTime()) / 1000)
  }

  const eCommerce = (event: EcommerceEvents, action: GaActionData) => {
    const data = {
      ...action,
      send_to: propertyIds,
    }
    send('event', event, data)
  }

  const engagement = (event: EngagementEvents, action?: GaActionData) => {
    const data = {
      ...action,
      send_to: propertyIds,
    }
    send('event', event, data)
  }

  const sendCustomEvent = (
    event: string,
    category: string,
    label: string,
    value?: number
  ) => {
    const data = {
      event_category: category,
      event_label: label,
      value,
      send_to: propertyIds,
    }
    send(event, 'event', data)
  }

  const send = (...args) => {
    if (isActive && 'gtag' in window) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.gtag(...args)
    }
  }

  return {
    init: async (config: PageConfig) => {
      if (config.ga) {
        const gaConf = config.ga
        isActive = true

        if (gaConf.gtag) {
          createGtmScript(gaConf.gtag)
        } else if (gaConf.trackingId) {
          createGtagScript(gaConf.trackingId)
        }

        if (gaConf.partner) {
          if (gaConf.partner.gtag) {
            createGtmScript(gaConf.partner.gtag)
          } else if (gaConf.partner.trackingId) {
            createGtagScript(gaConf.partner.trackingId)
          }
        }

        propertyIds.push(gaConf.trackingId)
        send('js', new Date())
        send('config', gaConf.trackingId) // GA_MEASUREMENT_ID

        if (gaConf.partner && gaConf.partner.trackingId) {
          send('config', gaConf.partner.trackingId)
          propertyIds.push(gaConf.partner.trackingId)
          if (gaConf.partner.conversionId) {
            conversionId = gaConf.partner.conversionId
          }
        }
      }
    },

    searched: (term: string) =>
      engagement(EngagementEvents.Search, { search_term: term }),

    viewed: (offers: Offer[]) =>
      engagement(EngagementEvents.ViewItemList, {
        items: convertOffers(offers),
      }),

    addedToCart: (params: {
      name: string
      offerId: string
      ticketTypeName: string
      ticketTypeId: string
      offerPrice: number
      currency: string
      quantity: number
      coupon?: string
      type?: string
    }) =>
      eCommerce(EcommerceEvents.AddToCart, {
        items: [
          {
            item_id: params.ticketTypeId,
            item_name: params.offerId,
            item_category: params.ticketTypeName,
            item_category2: params.name,
            quantity: params.quantity,
            price: params.offerPrice,
            currency: params.currency,
            item_variant: params.type || 'ticket',
          },
        ],
        coupon: params.coupon,
      }),
    checkoutStarted: (cartId: string, offers: Offer[]) =>
      eCommerce(EcommerceEvents.BeginCheckout, createCartAction(offers)),

    checkoutExpired: () =>
      sendCustomEvent(
        'cart-expired',
        'cart',
        'CartExpired',
        getFinishTime(checkoutStartTime)
      ),

    paymentInfoAdded: (offers: Offer[]) =>
      eCommerce(EcommerceEvents.CheckoutProgress, createCartAction(offers)),

    formInvalid: () =>
      sendCustomEvent('cart-form-invalid', 'cart', 'Form is invalid'),

    appliedCoupon: () =>
      sendCustomEvent('applied-coupon', 'event-box', 'Applied Coupon'),

    appliedCode: () =>
      sendCustomEvent('applied-code', 'event-box', 'Applied Code'),

    openedSeatmap: () =>
      sendCustomEvent('opened-seatmap', 'event-box', 'Opened Seatmap'),

    paymentStarted: () =>
      sendCustomEvent(
        'cart-payment-started',
        'cart',
        'Payment started',
        getFinishTime(checkoutStartTime)
      ),

    paymentFailure: () =>
      sendCustomEvent(
        'cart-payment-failure',
        'cart',
        'Payment failure',
        getFinishTime(paymentStartTime)
      ),

    purchased: (id: string, offers: Offer[]) => {
      const gaObj = createCartAction(offers)
      gaObj.transaction_id = id
      gaObj.affiliation = 'TIXA.hu'
      eCommerce(EcommerceEvents.Purchase, gaObj)

      if (conversionId) {
        send('event', 'conversion', {
          send_to: conversionId,
          value: gaObj.value,
          currency: offers[0].priceCurrency,
        })
      }
    },

    registered: (value: number) =>
      sendCustomEvent('cart-registered', 'cart', 'Registered in cart', value),

    closePage: () =>
      sendCustomEvent('cart-closed-page', 'cart', 'Closed page in cart'),

    gotIntoQueue: () => {
      queueStartTime = new Date()
      sendCustomEvent('queue-start', 'queue', 'Start queue')
    },

    leftQueue: () =>
      sendCustomEvent(
        'queue-left',
        'queue',
        'Left queue',
        getFinishTime(queueStartTime)
      ),

    finishQueue: () =>
      sendCustomEvent(
        'queue-finished',
        'queue',
        'Queue finished',
        getFinishTime(queueStartTime)
      ),

    queueSoldout: () =>
      sendCustomEvent(
        'queue-soldout',
        'queue',
        'Queue sold out',
        getFinishTime(queueStartTime)
      ),
  }
}

export { createGoogleAnalyticsHandler }

enum EngagementEvents {
  Login = 'login',
  Search = 'search',
  SelectContent = 'select_content',
  Share = 'share',
  SignUp = 'sign_up',
  ViewItem = 'view_item',
  ViewItemList = 'view_item_list',
  ViewPromotion = 'view_promotion',
  ViewSearchResults = 'view_search_results',
}

enum EcommerceEvents {
  AddPaymentInfo = 'add_payment_info',
  AddToCart = 'add_to_cart',
  AddToWishlist = 'add_to_wishlist',
  BeginCheckout = 'begin_checkout',
  CheckoutProgress = 'checkout_progress',
  GenerateLead = 'generate_lead',
  Purchase = 'purchase',
  Refund = 'refund',
  RemoveFromCart = 'remove_from_cart',
  SetCheckoutOption = 'set_checkout_option',
}

interface GaActionData {
  /** When action type is purchase or refund **/
  id?: string
  transaction_id?: string
  affiliation?: string
  value?: number
  tax?: number
  shipping?: number
  items?: GaProduct[]
  coupon?: string
  send_to?: string[]
  /** Search event **/
  search_term?: string
}

interface GaProduct {
  item_id: string
  item_name: string
  item_category: string
  item_category2?: string
  quantity?: number
  price?: number
  currency?: string
  coupon?: string
  item_variant?: string
}
