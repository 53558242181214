import { observable, observableArray } from 'knockout-decorators'

import { Page } from './pages/page'
import { ItemList, Location } from '@tixa/schema'
import { getPageData } from './utils/ajax'

import './components/item-place/item-place'
import './components/list-event/list-event'
import './components/page-sidebar/page-sidebar'
import './components/slider/slider'

import './components/item-event/item-event.less'
import './index.less'

export class HomePage extends Page {
  @observableArray public locations: Location[] = []
  @observable public locationSectionTitle = ''
  @observable public locationSectionLink: string | null = null

  constructor() {
    super()
    this.init()
  }

  public async init() {
    const locData = getPageData<ItemList<Location>>('topLocationEvents')
    if (locData) {
      this.locationSectionTitle = locData.name
      if (locData.url) {
        this.locationSectionLink = locData.url
      }
      locData.itemListElement.forEach((location) =>
        this.locations.push(location.item)
      )
    }
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const home = new HomePage()
