import { component, observable } from 'knockout-decorators'

import { pageState } from '~/store/page'
import { ErrorReporter } from '../../utils/error-reporting'

import source from './page-sidebar.html?raw'

@component('page-sidebar', source)
export class PageSidebar {
  @observable public showPromo = false
  @observable public promoImage = ''
  @observable public promoUrl = ''
  @observable public promoTitle = ''
  @observable public promoTarget = ''

  private impressionCount = 0

  constructor() {
    const config = pageState.config
    if (config.promo) {
      this.promoImage = config.promo.image
      this.promoTitle = config.promo.title
      this.promoUrl = config.promo.url
      if (config.promo.target) {
        this.promoTarget = config.promo.target
      }
      this.showPromo = true
    }
  }

  public async promoClicked() {
    try {
      await pageState.api.impression.submit({
        url: this.promoUrl,
        image: this.promoImage,
        page: document.location.href,
        action: 'click',
        type: 'promo',
        count: this.impressionCount,
      })
    } catch (error) {
      ErrorReporter.exception(error as Error)
    }
    if (this.promoTarget === 'blank') {
      const newWindow = window.open(this.promoUrl, this.promoTarget)
      ;(newWindow as Window).opener = null
    } else {
      document.location.href = this.promoUrl
    }
  }

  public async imageVisible() {
    this.impressionCount += 1
    try {
      await pageState.api.impression.submit({
        url: this.promoUrl,
        image: this.promoImage,
        page: document.location.href,
        type: 'promo',
        action: 'view',
        count: this.impressionCount,
      })
    } catch (error) {
      ErrorReporter.exception(error as Error)
    }
  }
}
